<template>
  <div>
    <div class="blue-title font18 color-white align-center">联系方式</div>
    <div class="bg-white">
      <div class="informatiton">
        <span>参 展</span>
        冯卫章 19912348021
      </div>
      <div class="informatiton">
        <span>参 观</span>
        闫文静 19912348097
      </div>
      <div class="informatiton">
        <span>媒体合作</span>
        马妍 18633381079
      </div>
      <div class="informatiton">
        <span>地 址</span>
        河北省唐山市南湖国际会展中心
      </div>
      <div class="informatiton">
        <el-image :src="src" style="width:150px;" alt="冶金炉料博览会小程序"></el-image>
        <div>冶金炉料博览会小程序</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name:"contact",
    data() {
    return {
        src:require('@/assets/images/position_tip.png')
    };
  }
};
</script>
<style scoped>
.blue-title {
	height: 50px;
    background-color: #073290;
    line-height: 50px;
}
.informatiton{
    padding: 10px 30px;
    color: #333;
}
.informatiton span{
    display: block;
    font-weight: 600;
}
.informatiton .el-image img{
    display: block;
    margin: 5px auto;
}
.bg-white{
    padding: 10px 0;
}
</style>