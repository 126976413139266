<template>
  <div>
    <topmenu :comname="'contactus'"></topmenu>
    <div class="widht1240">
      <div class="t-map">
        您当前的位置：
        <span>联系我们</span>
      </div>
      <div class="widht200 fl">
        <contact></contact>
      </div>
      <div class="widht1030 bg-white fr">
        <div class="pd25">
          <div class="amap-page-container">
            <el-amap
              class="amap-box"
              :vid="'amap-vue'"
              :amap-manager="amapManager"
              :center="center"
              :zoom="zoom"
              :plugin="plugin"
              :events="events"
            >
              <el-amap-marker v-for="(marker, index) in markers" :key="index" :position="marker"></el-amap-marker>
              <el-amap-info-window
                :position="window.position"
                :visible="window.visible"
                :content="window.content"
                :offset="window.offset"
              ></el-amap-info-window>
            </el-amap>
          </div>
        </div>
      </div>
    </div>
    <bottominfo></bottominfo>
  </div>
</template>
<script>
import { AMapManager, lazyAMapApiLoaderInstance } from "vue-amap";
let amapManager = new AMapManager();
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
import contact from "@/components/public/contact";
export default {
  name: "contactus",
  data() {
    let self = this;
    return {
      amapManager,
      markers: [[118.151736, 39.605966]],
      center: [118.151736, 39.605966],
      zoom: 15,
      lng: 118.151736,
      lat: 39.605966,
      loaded: false,
      window: {
        position: [118.151736, 39.605966],
        content: "<div id='content'>唐山南湖国际会展中心</div>", //内容
        offset: [2, -25], //窗体偏移
        visible: true //初始是否显示
      },
      events: {
        click(e) {}
      },
      // 一些工具插件
      plugin: [
        {
          // 定位
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量，默认：Pixel(10, 20)
          //buttonOffset: new AMap.Pixel(10, 20),
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          pName: "Geolocation",
          events: {
            init(o) {
              // o是高德地图定位插件实例
              console.log(self.markers[0], "marker");
              // 设置经度
              self.lng = self.markers[0][0];
              // 设置维度
              self.lat = self.markers[0][1];
              self.center = [self.lng, self.lat];
              console.log(self.center, "中心");
              self.loaded = true;
              // 页面渲染好后
              self.$nextTick();
            }
          }
        },
        {
          // 工具栏
          pName: "ToolBar",
          events: {
            init(instance) {
              // console.log(instance);
            }
          }
        },
        {
          // 地图类型
          pName: "MapType",
          defaultType: 0,
          events: {
            init(instance) {
              // console.log(instance);
            }
          }
        }
      ]
    };
  },
  components: {
    topmenu,
    bottominfo,
    contact
  },
  created() {}
};
</script>
<style scoped>
.pd25 {
  padding: 25px;
  height: 600px;
}
.amap-page-container {
  height: 600px;
  width: 100%;
}
</style>