export default {
    //去掉html标签只留文本
    removeLab(str) {
        return str.replace(/<[^>]+>|&[^>]+;/g, "");
    },
    //限制文本字数
    limitNum(str, num) {
        return str.substr(0, num) + "..."
    },
    //获取当前时间
    getTime() {
        let data = new Date();
        let year = data.getFullYear();
        let month = data.getMonth() + 1;
        let day = data.getDate();
        let hours = data.getHours();
        let minutes = data.getMinutes();
        let seconds = data.getSeconds();
        return year + "-" + (month < 10 ? ("0" + month) : month) + "-" + (day < 10 ? ("0" + day) : day) + " " + (hours < 10 ? ("0" + hours) : hours) + ":" + (minutes < 10 ? ("0" + minutes) : minutes) + ":" + (seconds < 10 ? ("0" + seconds) : seconds)
    }
}