import Vue from 'vue'
import Router from 'vue-router'
import index from '@/components/index'
import contactus from '@/components/contactus.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: index,
      meta: {
        title: "首页"
      }
    },
    {
      path: '/introduce',
      name: 'exintroduce',
      component: resolve => require(['@/components/introduce.vue'], resolve),
      meta: {
        title: "大会介绍"
      }
    },
    {
      path: '/forum',
      name: 'forum',
      component: resolve => require(['@/components/forum.vue'], resolve),
      meta: {
        title: "同期活动"
      }
    },
    {
      path: '/forumdetails',
      name: 'forumdetails',
      component: resolve => require(['@/components/forumdetails.vue'], resolve),
      meta: {
        title: "同期活动详情"
      }
    },
    {
      path: '/enterprise',
      name: 'enterprise',
      component: resolve => require(['@/components/enterprise.vue'], resolve),
      meta: {
        title: "参展企业"
      }
    },
    {
      path: '/specimens',
      name: 'specimens',
      component: resolve => require(['@/components/specimens.vue'], resolve),
      meta: {
        title: "大会集锦  "
      }
    },
    {
      path: '/dynamic',
      name: 'dynamic',
      component: resolve => require(['@/components/dynamic.vue'], resolve),
      meta: {
        title: "大会动态"
      }
    },
    {
      path: '/dynamicdetails',
      name: 'dynamicdetails',
      component: resolve => require(['@/components/dynamicdetails.vue'], resolve),
      meta: {
        title: "媒体聚焦详情"
      }
    },
    {
      path: '/information',
      name: 'information',
      component: resolve => require(['@/components/information.vue'], resolve),
      meta: {
        title: "资料下载"
      }
    },
    {
      path: '/associatedMedia',
      name: 'associatedMedia',
      component: resolve => require(['@/components/associatedMedia.vue'], resolve),
      meta: {
        title: "合作媒体"
      }
    },
    {
      path: '/floorplan',
      name: 'floorplan',
      component: resolve => require(['@/components/floorplan.vue'], resolve),
      meta: {
        title: "展位图"
      }
    },
    {
      path: '/csshi',
      name: 'ceshi',
      component: resolve => require(['@/components/floorplan.vue'], resolve),
      meta: {
        title: "测试"
      }
    },
    {
      path: '/mediaFocus',
      name: 'mediaFocus',
      component: resolve => require(['@/components/mediaFocus.vue'], resolve),
      meta: {
        title: "媒体聚焦"
      }
    },
 
    {
      path: '/contactus',
      name: 'contactus',
      component: contactus,
      meta: {
        title: "联系我们"
      }
    },
    {
      path:'*',
      name:'404',
      meta: {
        title: "404"
      },
      component: resolve => require(['@/components/load404.vue'], resolve) 
    }
  ]

})
