<template>
  <div id="box" class="style_bg_color_main">
     
    <topmenu :comname="'index'"></topmenu>
    <div class="contactus_div_bg"></div>  
    <div class="bgml" id="introduce">
      <div class="widht1240">
        <div class="title">大会介绍</div>
        <div class="widht1240">
          <img src="@/assets/images/details_bg.png" alt="" class="details_bg">
          <div class="fl details_video">
            <video
              controls
              :src="require('@/assets/images/yasuo.mp4')"
              width="613"
              height="347"
              style="border-radius: 5px;"
            ></video>
          </div>
          <div class="fr dhcontent font14 style_color_ash">
            {{introduce.length>280?comJs.limitNum(introduce,260):introduce}}
            <br>
            <br>
            <router-link :to="{name:'exintroduce',query:{id:1}}">
              <!-- <span class="red">[查看详情]</span> -->
              
              <!-- <button type="button" class="details">查看详情></button> -->
              <a href="javascript:void(0);" class="details">查看详情</a>
            </router-link>
          </div>
        </div>        
        <div class="title">联系方式</div>
        <div class="contactus">   
          <div class="contal fl contactus_div">
            <!-- <i class="czqy"></i> -->
            <img src="@/assets/images/contal_1.png" alt="">
            <span class="font16 style_color_main">参展</span>
            <p class="font16 style_color_ash">冯卫章 <span class="dis_in_bl font16"> 199-1234-8021</span></p>
          </div>
          
          <div class="contal fl contactus_div">
            <!-- <i class="czqy"></i> -->
            <img src="@/assets/images/contal_2.png" alt="" class="img_h">
            <span class="font16 style_color_main">观展</span>
            <p class="font16 style_color_ash">闫文静 <span class="dis_in_bl font16"> 199-1234-8097</span></p>
          </div>
          <div class="contal fl contactus_div">
            <!-- <i class="czqy"></i> -->
            <img src="@/assets/images/contal_3.png" alt="" class="img_h">
            <span class="font16 style_color_main">媒体合作</span>
            <p class="font16 style_color_ash">马妍 <span class="dis_in_bl font16"> 186-3338-1079</span></p>

          </div>
          <div class="contal fl contactus_div">
            <!-- <i class="czqy"></i> -->
            <img src="@/assets/images/contal_4.png" alt="" class="img_h">
            <span class="font16 style_color_main">地址</span>
            <span class="font14 style_color_ash">河北省唐山市南湖国际会展中心</span>
          </div>
          <div class="contal fl contactus_div">
            <el-image :src="src" style="width:90px; height:90px; margin-top:25px; margin-bottom:20px" alt="冶金炉料博览会小程序"></el-image>
            <span class="font14 style_color_ash">冶金炉料博览会小程序</span>
          </div>
        </div>
      </div>
    </div>
    <div id="company">
      <div class="widht1240">
        <div class="title">参展企业 
          <router-link :to="{name:'enterprise',query:{id:1}}"><a href="" class="company_more font14">更多></a></router-link>
          
          </div>
        <div class="exhibitors_left">
          <router-link :to="{name:'floorplan'}">
            <!-- <img :src="curUrl" alt="展位图" width="100%" height="100%"> -->
            <el-image 
                    style="width: 100%; height: 100%"
                    fit="contain"
                    :src="curUrl">
                    
                </el-image>
          </router-link>
          
        </div>
        <el-carousel height="518px" arrow="always" indicator-position="none" class="exhibitors_carousel_width" style="float:left;">
          <el-carousel-item v-for="(item1,index) in companyList" :key="index" class="width_carousel">
            <ul class="compaylist width_carousel" >
              <li v-for="item in item1" :key="item.id" class="bg-white compaylist_li" style="padding:15px">
                <!-- <a :href="'http://www.yyj086.com/Room/index?iUiIdentifier='+item.iUiIdentifier" target="_blank"> :rel="noFollow(item.url)"--> 
                <a :href="item.url" :rel="noFollow(item.url)" target="_blank">
                  <p class="iUiIdentifier" v-if="item.vBoothNumber == null?false:true">{{item.vBoothNumber}}</p>
                  <el-image :src="item.nvcLogo?item.nvcLogo:require('@/assets/images/dtmr.png')"
                    fit="contain" :alt="item.nvcLogo?item.nvcCompanyName:''"
                    :title="item.nvcCompanyName"
                    style="width:201px; height:98px; border-radius: 5px;"
                  ></el-image>
                  <span :title="item.nvcCompanyName">{{item.nvcCompanyName!=null&&item.nvcCompanyName.length>10?comJs.limitNum(item.nvcCompanyName,10):item.nvcCompanyName}}</span>
                </a>
              </li>
            </ul>
          </el-carousel-item>
        </el-carousel>
        <div class="more">
        <router-link :to="{name:'enterprise',query:{id:1}}" v-if="companyNum>12">查看更多+</router-link>
        </div>
      </div>
    </div>
    <div id="forun">
      <div class="widht1240 bg_forun">
        <div class="title">同期活动</div>
        <div class="foruminfor">
          <ul>
            <li v-for="(item) in forumList" :key="item.id">
              <div class="fl bg-white border5 wh160">
                <router-link :to="{name:'forumdetails',query:{id:item.id}}" class="imgwid">
                    <el-image
                      :src="item.vImgUrl?item.vImgUrl:require('@/assets/images/dtmr.png')" :alt="item.vName"
                      fit="contain"
                      :title="item.vName"
                      style="width:160px; height:160px; border-radius: 5px;"
                    ></el-image>
                  </router-link>
              </div>
              <div class="lun fl activity_right">
                <div style="margin-bottom:29px">
                  <router-link :to="{name:'forumdetails',query:{id:item.id}}">
                    <span
                      class="tlew"
                      style="margin-bottom:29px"
                      :title="item.vName"
                    >{{item.vName.length>21?comJs.limitNum(item.vName,21):item.vName}}</span>
                  </router-link>
                </div>

                {{item.vAbstract!=null&&item.vAbstract.length>60?comJs.limitNum(item.vAbstract,60):item.vAbstract}}
                
                <div style="margin-top:25px">
                  <router-link  :to="{name:'forumdetails',query:{id:item.id}}">
                  <span class="style_color_main">
                    查看详情>
                    <!-- <img :src="require('@/assets/images/jiantou-left.png')" /> -->
                  </span>
                </router-link>
                </div>
              </div>
                
              
            </li>
          </ul>
        </div>
         <!-- <div class="more">
        <router-link :to="{name:'forum'}" v-if="forumNum>3">查看更多+</router-link>
         </div> -->
      </div>
    </div>
    <div id="dynamic">
      <div class="widht1240">
        <div class="title">大会动态
          
          <router-link :to="{name:'dynamic'}"><a href="" class="company_more font14">更多></a></router-link>
          </div>
        <div class="listinfor">
          <div v-for="(item,index) in dynamicList" :key="item.id">
            <div v-if="index==0" class="first fl">
              <a class="bgtsa" @click="jumpDteails(item)">
                <el-image
                  :src="item.vImgUrl?item.vImgUrl:require('@/assets/images/dtmr.png')" :alt="item.vImgUrl?item.vName:''"
                  fit="cover"
                  style="width:544px; height:323px; border-radius: 5px;"
                ></el-image>
              </a>
              <div class="contm1">
                <div class="fl eaes font16 color-white">   
                  <span
                  @click="jumpDteails(item)"
                    :title="item.vName"
                  >{{item.vName.length>24?comJs.limitNum(item.vName,24):item.vName}}</span>   
                  <div class="font14 pd_top_21">
                    {{item.vAbstract!=null&&item.vAbstract.length>50?comJs.limitNum(item.vAbstract,50):item.vAbstract}}
                    <!-- <a @click="jumpDteails(item)">
                      <span class="red">[查看更多]</span>
                    </a> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="listcont fl">
              <div class="list" v-if="index>0">
                <a @click="jumpDteails(item)" class="fl cur bg-white border5">
                  <el-image
                    :src="item.vImgUrl?item.vImgUrl:require('@/assets/images/dtmr.png')" :alt="item.vImgUrl?item.vName:''"
                    fit="contain"
                    style="width:80px; height:82px; border-radius: 5px;"
                  ></el-image>
                </a>
                <div class="contm">
                  <a @click="jumpDteails(item)">
                    <span
                      class="tlew font14 td dynamic_color_blue"
                      :title="item.vName"
                    >{{item.vName.length>28?comJs.limitNum(item.vName,28):item.vName}}</span>
                  </a>
                  <div class="lun font14 dynamic_color_blue">
                    {{item.vAbstract!=null&&item.vAbstract.length>40?comJs.limitNum(item.vAbstract,40):item.vAbstract}}
                    <!-- <a @click="jumpDteails(item)">
                      <span class="red">[查看更多]</span>
                    </a> -->
                  </div>
                  <a @click="jumpDteails(item)" class="time_more">></a>
                </div>
                <div class="time fr dynamic_color_blue">
                    <span class="font24 fr">{{item.dCreateTime | formatDate | todate1 }}</span><br>
                    <span class="font12 fr">{{item.dCreateTime | formatDate | todate2 }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="more">
        <router-link :to="{name:'dynamic'}" v-if="dynamicNum>4">查看更多+</router-link>
        </div> -->
      </div>
    </div>
    <div class="mt15">
      <bottominfo></bottominfo>
    </div>
    <div class="t_menulist" v-if="ishowsa">
      <ul>
        <li @click="getejfl('introduce','0')" :class="hoded=='0'?'t_xzhouer':''">大会介绍</li>
        <li @click="getejfl('company','1')" :class="hoded=='1'?'t_xzhouer':''">参展企业</li>
        <li @click="getejfl('forun','2')" :class="hoded=='2'?'t_xzhouer':''">同期活动</li>
        <li @click="getejfl('dynamic','3')" :class="hoded=='3'?'t_xzhouer':''">大会动态</li>
        <li class="t-gotop" @click="getejfl('box','4')">
          <img :src="require('@/assets/images/jiantou.png')">TOP
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import topmenu from "@/components/public/top";
import bottominfo from "@/components/public/bottom";
export default {
  name: "index",
  data() {
    return {
      introduce: "",
      src: require('@/assets/images/position_tip.png'),
      dynamicList: [],
      dynamicNum:'',
      forumList: [],
      forumNum:'',
      companyList: [],
      companyNum:"",
      hoded:'0',
      ishowsa:false,
      curUrl:""
    };
  },
  methods: {
    noFollow (n) {
            if(n && n.indexOf("086") == -1)
            {
              return 'nofollow'
            }
            else{
             return ''
            }     
      },
    getBooth(){
            this.$axios.post("/api/rally/getExhibitionImgCollection").then(res => {
            if (res[0].length > 0) {               
                let demo = res[0];
                this.curUrl = demo[0].imgUrl;
                // console.log(this.curUrl);
            }
            });
        },
    getejfl(num,state) {
      if(state!='4'){
        this.hoded = state
      }
      document.getElementById(num).scrollIntoView();
    },
    handleScroll(e) {
      var scrollTop = e.srcElement.scrollingElement.scrollTop;
      if(scrollTop<600){
        this.ishowsa=false;
      }else{
      this.ishowsa=true;
      } 
      if (scrollTop < document.getElementById("introduce").offsetTop) {
        this.hoded = "0";
      } else if (
        scrollTop > document.getElementById("introduce").offsetTop &&
        scrollTop < document.getElementById("company").offsetTop
      ) {
        this.hoded = "1";
      } else if (
        scrollTop > document.getElementById("company").offsetTop &&
        scrollTop < document.getElementById("forun").offsetTop
      ) {
        this.hoded = "2";
      } else if (
        scrollTop > document.getElementById("forun").offsetTop &&
        scrollTop < document.getElementById("dynamic").offsetTop
      ) {
        this.hoded = "3";
      } 
    },
    getintroduce() {
      //大会介绍
      this.$axios.post("/api/app/exhibition/getExhibitionDetail", {}).then(res => {
        if (res[0].length > 0) {
          let infordata = res[0];
          console.log(infordata,"大会介绍文字");
          this.introduce = this.comJs.removeLab(infordata[0].vIntroduceContent);
        }
      });
    },
    getdynamic() {
      //大会动态
      let data = {
        current: 1,
        size: 4,
        isPage: true
      };
      this.$axios
        .post("/api/app/exhibitionDynamic/getExhibitionDynamicPageList", data)
        .then(res => {
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(res,'动态');
            this.dynamicList = infordata;
            this.dynamicNum=res[1].total;
          }
        });
    },
    getforum() {
      //同期活动
      let data = {
        current: 1,
        size: 4,
        isPage: true
      };
      this.$axios
        .post("/api/app/exhibitionForum/getExhibitionForumPageList", data)
        .then(res => {

          console.log(111111,res);
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata);
            this.forumList = infordata;
            this.forumNum=res[1].total;
          }
        });
    },
    getinformation() {
      //参展企业
      let data = {
        current: 1,
        size: 120,
        isPage: true,
        itype: 1
      };
      console.log(data);
      this.$axios
        .post("/api/app/exhibitionCompany/getExhibitionCompanyPageList", data)
        .then(res => {
          console.log(res,'234e232323')
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata,"参展企业");
            this.companyList = this.spArr(infordata,9);
            this.companyNum==res[1].total;
          }
        });
    },
    
    // 页面跳转判断
    jumpDteails(record){
      if(!record.webUrl){
        this.$router.push({
          // path: '/dynamicdetails',dynamic
          path: '/dynamicdetails',
          query: {
            id: record.id
          }
        })
      }else{
        window.open(record.webUrl)
        // window.location.href = record.webUrl
      }
    },
    spArr(arr, num) {
      //arr是你要分割的数组，num是以几个为一组
      let newArr = []; //首先创建一个新的空数组。用来存放分割好的数组
      for (let i = 0; i < arr.length; ) {
        //注意：这里与for循环不太一样的是，没有i++
        newArr.push(arr.slice(i, (i += num)));
      }
      return newArr;
    }
  },
  created() {
    this.getintroduce();
    this.getdynamic();
    this.getforum();
    this.getinformation();
    this.getBooth();
  },
  components: {
    topmenu,
    bottominfo
  },
   computed: {
     
   },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  filters: {
    formatDate: function(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d;
    },
    todate1(value) {
      let data = value.split("-");
      return data[1] + "/" + data[2];
    },
    todate2(value) {
      let data = value.split("-");
      return data[0];
    }
  }
};
</script>
<style scoped>
.bgml {
  /* background-color: #f2f9fe; */
  overflow: hidden;
}
.title {
  font-size: 26px;
  line-height: 26px;
  padding: 45px 0 60px;
  color: #666;
  position: relative;
  /* font-weight: 700; */
  /* border-bottom: 2px solid #CFCFCF; */
}
.title::before{
  width: 470px;
  height: 1px;
  content: "";
  background: #CFCFCF;
  position: absolute;
  top: 92px;
  left: 32%;
}
.title::after {
  position: absolute;
  width: 88px;
  height: 3px;
  left: 48.5%;
  margin-left: -25px;
  top: 90px;
  content: "";
  z-index: 7;
  background-color: #073290;
}
.dhcontent {
  width: 580px;
  height: 295px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 30px;
  text-align: justify;
  text-indent: 2em;
  padding: 26px 17px;
  color: #000;
}
.mt15 {
  margin-top: -15px;
}
.contactus {
  /* height: 156px; */
  height: 260px;
  overflow: hidden;
  clear: both;
  margin-top: 25px;
  margin-bottom: 48px;
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 10;
}
.lxfs {
  font-size: 26px;
  font-weight: bold;
  color: #e9eaea;
  width: 30px;
  padding: 18px 36px;
  line-height: 30px;
}
.more {
  color: #343239;
  padding: 30px 0 40px;
  display: block;
  clear: both;
}
.contal {
  padding: 18px 33px;
  color: #e2e2e2;
}
.contal span {
  display: block;
  line-height: 30px;
}
.contal span.font16 {
  font-weight: 600;
}
.czqy {
  background: url("../assets/images/common.png") no-repeat -48px -18px;
  width: 38px;
  height: 42px;
  display: block;
  margin: 10px auto;
}
.cgqy {
  background: url("../assets/images/common.png") no-repeat -248px -18px;
  width: 40px;
  height: 42px;
  display: block;
  margin: 10px auto;
}
.mehz {
  background: url("../assets/images/common.png") no-repeat -441px -18px;
  width: 46px;
  height: 42px;
  display: block;
  margin: 10px auto;
}
.dzmap {
  background: url("../assets/images/common.png") no-repeat -696px -18px;
  width: 38px;
  height: 42px;
  display: block;
  margin: 10px auto;
}
.listcont {
  border-left: 1px solid #efefef;
  /* padding-left: 23px; */
  margin-bottom: 36px;
}
.listinfor {
  height: 330px;
  overflow: hidden;
  margin-bottom: 60px;
}
.listinfor .list {
  display: block;
  /* background: #fafafa; */
  /* padding: 15px 10px; */
  width: 652px;
  /* margin-bottom: 15px; */
  position: relative;
  height: 80px;
}

/* .listinfor .list::before {
  position: absolute;
  content: "";
  width: 15px;
  height: 15px;
  background-color: #fff;
  border: solid 2px #d2d2d2;
  border-radius: 15px;
  left: -33px;
  top: 55px;
}
.listinfor .list:hover::before {
  border: solid 2px #013eb2;
} */
.listinfor .first {
  width: 544px;
  position: relative;
  margin-right: 41px;
}
.contm {
  text-align: left;
  padding: 0 5px 0 15px;
  float: left;
  width: 390px;
}
.contm1 {
  background-color: rgba(68, 103, 207, 0.8);
  position:absolute;
  bottom:2px;
  border-radius: 0 0 5px 5px;
  text-align: left;
  color: #fff !important;
  /* padding: 25px 5px 10px 15px; */
  padding-left:41px ;
  padding: 18px 41px 0;
  float: left;
  width: 462px;
  height: 101px;
}
.bgtsa {
  display: block;
  background-color: #f3f3f3;
}
.color-666 {
  width: 80px;
  padding-top: 5px;
}
.color-666 span {
  display: block;
  line-height: 30px;
  text-align: center;
}
.contm1 .eaes {
  width: 480px;
}
.lun {
  line-height: 22px;
  padding-top: 21px;
  color: #666;
}
.tlew {
  font-size: 18px;
  /* line-height: 22px; */
  color: #333;
}
.time {
  /* font-size: 15px; */
  color: #999;
  width: 70px;
  height: 40px;
  /* padding: 20px 0; */
}
.foruminfor {
  overflow: hidden;
  position: relative;
  z-index: 2;
}
.foruminfor li {
  /* background-color: #fff; */
  /* border: 1px solid #e9e9e9; */
  padding: 10px;
  width: 573px;
  height: 196px;
  float: left;
  margin-top: 30px;
}
/* .foruminfor li:nth-child(2n) {
  width: 400px;
  margin: 0 17px;
  height: 370px;
} */
.forumcont {
  /* text-align: left; */
  display: inline-block;
  padding-top: 10px;
}
.forummore {
  padding-right: 15px;
}
.forummore img {
  margin-left: 5px;
}
.compaylist ul{
  margin-left: -19px;
}
.compaylist li{
width: 204px;
float: left;
margin-left: 19px;
border-radius: 5px;
/* margin-top: 12px; */
margin-bottom: 10px;
}
.compaylist li .el-image{
  border: 1px solid #e4e4e4;
}
.compaylist li span{
  height: 32px;
  line-height: 32px;
  margin-top: 2px;
	background-color: #f1f1f1;
  border-radius: 2px;
  font-size: 16px;
  color:#343239;
  display: block;
}
.compaylist li:hover span{
  background-color: #4467CF;
  color: #fff;
}
.compaylist li:hover .el-image{
  border: solid 1px #4467CF;
}
.t_menulist {
  position: fixed;
  z-index: 10;
  top: 350px;
  left: 50%;
  margin-left: 650px;
  width: 64px;
  border: 1px solid #B2CFFE;
}
.t_menulist li {
  display: block;
  cursor: pointer;
  background: #F1F5FE;
  border-bottom: 1px solid #f2f2f2;
  width: 40px;
  padding: 13.5px 12px;
  text-align: center;
  color: #666;
}
.t_menulist li:hover,
.t_menulist li.t_xzhouer {
  background: #4467CF;
  border-bottom: 1px solid #4467CF;
  color: #fff;
}
.t_menulist li.t-gotop {
  border: 0;
}
.t_menulist li.t-gotop img {
  display: block;
  margin: 5px auto;
}
.t_menulist li.t-gotop:hover {
  background: #4467CF;
  color: #333;
}
.cur{
  cursor: pointer;
}

/* 2021.09.08 */
.details{
  display: inline-block;
  border-radius: 6px !important;
  width: 126px;
  height: 50px;
  background: #4467CF;
  color: #fff;
  font-family: "MicrosoftYaHei";
  font-size: 7px;
  text-indent: 0;
  text-align: center;
  line-height: 48px;
  /* padding-left: 10px; */
  /* padding: 9px 15px; */
}
.details_bg{
  position: absolute;
  /* width: 195px;
  height: 191px; */
  left: 234px;
  top: 685px;
  z-index: 10;
}
.details_video{
  position: relative;
  z-index: 11;
}

.contactus_div_bg{
background: url("../assets/images/contactus_bg.png") no-repeat center;
background-size:100% 100%;
width: 100%;
height: 156px;
position: absolute;
top: 1240px;
left: 0;
z-index: 9;
}
/* 联系方式 */
.contactus_div{
  width: 162px;
  height: 192px;
  background: #fff;
  border-radius: 3px;
  margin: 0 13px;
}
.contactus_div img{
  margin-top: 25px;
  margin-bottom: 38px;
}

/* 参展企业 */
.exhibitors_carousel_width{
  width: 759px;
}
.exhibitors_left{
  width: 471px;
  height: 508px;
  padding: 5px;
  background: #fff;
  border-radius: 3px;
  float: left;
}

.activity_right{
  width: 380px;
  margin-left: 10px;
  text-align: left;
  /* margin-bottom: 29px; */
}
.img_h{
  height: 52px;
}
.dis_in_bl{
  display: inline-block !important;
}
.pd_top_21{
  padding-top: 21px;
}
#dynamic{
  position: relative;
  z-index: 10;
}
.dynamic_color_blue:hover{
    color:#4467CF;
}
#forun{
  position: relative;
}

.bg_forun::after{
  width: 390px;
  height: 390px;
  position: absolute;
  right: 255px;
  top: 236px;
  z-index: 1;
  content: "";
  background: url(../assets/images/details_bg.png) no-repeat;
  /* background-position: 10px 10px; */
  transform: rotate(180deg);
}

.time_more{
  position: absolute;
  right: 0;
  top: 57px;
  background: rgba(red, green, blue, 0);
  color:#666;
  width: 14px;
  height: 14px;
  border: 2px solid #666;
  padding: 0;
  opacity: 0.6;
  text-align: center;
  line-height: 14px;
  font-weight: 600;
}
.company_more{
  display: inline-block;
  font-size: 12px;
  color: #999;
  position: absolute;
  right: 0;
  /* right: 60px; */
  /* margin-right: 100px; */
}
.contactus div:first-child{
  margin-left:0 !important;
}
.contactus div:last-child{
  margin-right: 0 !important;
}
.el-carousel__arrow--right{
  right: 10px !important;
}
</style>
