<template>
  <div class="height">
      
      <!-- <div class="heng">
          <img :src="require('@/assets/images/heng.jpg')" alt="">
      </div>      -->
       <div class="navigation">
          <div class="flex">
              
              <ul class="menu">
                  <li :class="comname=='index'?'on':''"><router-link :to="{name:'index'}">首页</router-link></li>
                  <li :class="comname=='exintroduce'?'on':''"><router-link :to="{name:'exintroduce',query:{id:1}}">大会介绍</router-link></li>
                  <li :class="comname=='forum'?'on':''"><router-link :to="{name:'forum'}">同期活动</router-link></li>
                  <li :class="comname=='enterprise'?'on':''"><router-link :to="{name:'enterprise',query:{id:1}}">参展企业</router-link></li>
                  <!-- <li :class="comname=='enterprise2'?'on':''"><router-link :to="{name:'enterprise',query:{id:2}}">观展企业</router-link></li> -->
                  <li :class="comname=='dynamic'?'on':''"><router-link :to="{name:'dynamic'}">大会动态</router-link></li>
                  <li :class="comname=='specimens'?'on':''"><router-link :to="{name:'specimens'}">大会集锦</router-link></li>                 
                  <!-- <li :class="comname=='associatedMedia'?'on':''"><router-link :to="{name:'associatedMedia'}">合作媒体</router-link></li> -->
                  <li :class="comname=='mediaFocus'?'on':''"><router-link :to="{name:'mediaFocus'}">媒体聚焦</router-link></li>
                  <li :class="comname=='floorplan'?'on':''"><router-link :to="{name:'floorplan'}">展位图</router-link></li>
                  <li :class="comname=='information'?'on':''"><router-link :to="{name:'information'}">参展手册</router-link></li>
                  <!-- <li :class="comname=='ceshi'?'on':''"><router-link :to="{name:'ceshi'}">测试</router-link></li> -->
                  <!-- <li :class="comname=='contactus'?'on':''"><router-link :to="{name:'contactus'}">联系我们</router-link></li> -->
                  
                  
              </ul>
              <div class="bshare-custom icon-medium-plus position_relative">
                <span>分享到：</span>
                  <!-- <a title="分享到" href="http://www.bShare.cn/" id="bshare-shareto" class="color-white bshare-more"></a> -->
                  <a title="分享到微信" class="bshare-weixin wx"></a>
                  <a title="分享到QQ好友" class="bshare-qqim qq"></a>
                  <a title="分享到新浪微博" class="bshare-sinaminiblog wb"></a>
                </div>
              <ul class="onhengbank">
                  <li :class="comname=='index'?'on':''"></li>
                  <li :class="comname=='exintroduce'?'on':''"></li>
                  <li :class="comname=='forum'?'on':''"></li>
                  <li :class="comname=='enterprise'?'on':''"></li>
                  <li :class="comname=='dynamic'?'on':''"></li>
                  <li :class="comname=='specimens'?'on':''"></li>
                  <li :class="comname=='mediaFocus'?'on':''"></li>
                  <!-- <li :class="comname=='associatedMedia'?'on':''"></li> -->
                  <li :class="comname=='floorplan'?'on':''"></li>
                  <li :class="comname=='information'?'on':''"></li>

              </ul>
          </div>
      </div>
    <el-carousel height="600px"   trigger="click">
      <el-carousel-item v-for="(item,index) in list" :key="index">
        <img :src="item" alt="中国国际冶金炉料交易博览会" style="height: 100%;"/>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "topmenu",
  data() {
    return {
      list: [require('@/assets/images/bg2.jpg'),require('@/assets/images/bg.png')],
      list1: "",
      isshowlist: true,
      loading: true,
      currentPage: 1, //当前页
      pagesize: 6, //每页显示条数
      totalPage: 0 //总页数
    };
  },
  created(){
    this.getinformation();
  },
  props:["comname"],
  methods:{
    clickEffect(event){
      console.log(event.target.style);
      event.target.style.borderBottom='10px sold #fff'
    },

    getinformation() {
      let data = {
        current: this.currentPage,
        size: this.pagesize,
        isPage: true,
        iType: 1
      };
      this.$axios
        .post("/api/app/exhibitionMaterials/getExhibitionMaterialsPageList", data)
        .then(res => {
          this.loading = false;
          this.totalPage = res[1].pages;
          if (res[0].length > 0) {
            let infordata = res[0];
            console.log(infordata[0],"参展手册");
            this.list1 = infordata[0];
            this.isshowlist = true;
          } else {
            this.isshowlist = false;
          }
        });
    },

    downloadFile(fileName, data) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(new Blob([data]));
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    }
    

  }


  
  
};

</script>

<style scoped>
.el-carousel__item{
  display: flex;
  justify-content: center;
}
.height {
  height: 615px;
  position: relative;
  min-width: 1370px;
  width: 100%;
}
.height .el-image__inner{
    width: auto;
    height: 100%;
}
.heng img{
  width: 100%;
}
.navigation{
    background: rgba(0, 0, 0, 0);
    position: absolute;
    
    z-index: 10;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    /* justify-content: space-between; */
    justify-content: flex-start;
}
.navigation .menu{
  /* justify-content: center; */
  /* font-weight: bold; */
  /* background: url('../../assets/images/tab-bg.jpg'); */
  background: #182fb3;
}
.wdleft{
  width: 505px;
}
.flex{
  flex: 1;
}
.menu{
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  font-family: "微软雅黑";
  font-size: 16px;
  
}

.menu li{
  padding: 0 1.5% 0;
  height: 60px;
  line-height: 60px;
  width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  position: relative;
  display: inline-block;
  opacity: 0.9;
  font-weight: bold;
}
.menu li a{
  color: #fff;
  font-size: 16px;
}
.menu .on{
  color: #fff;
  opacity: 1;
  font-size: 16px;
  position: relative
}

/* .menu li:after {
  content: '';
  position: absolute;
  left: auto;
  top: 32%;
  right: 0;
  height: 35%;
  width: 1px;
  background-color: #e6e6e6;
} */
.menu li:last-child::after{
  display: none;
}
.onhengbank{
  /* background: #f5f5f5; */
  width: 100%;
  height:0px;
  background: rgba(200, 200, 200, 0.3);
  display: flex;
  justify-content: center;
  /* margin-left: 4%; */
}
.onhengbank li{
  height: 2px;
  width: 80px;
  padding: 0 1.5% 0;
  position: relative;
}

.onhengbank li.on::after{
  position: absolute;
  content: "";
  left: 25%;
  top: -1px;
  width: 52%;
  height: 2px;
  background-color: #fff;
}
.onhengbank li.on:first-child::after{
  width: 30%;
  left: 35%;
}
.onhengbank li.on:nth-child(7)::after{
  width: 39%;
  left: 32%;
}

.ml25{
  margin: 25px 10px 0 2%;
  display: inline-block;
}

/* 控制分享的位置 */
.bshare-custom a{
  display: inline-block;
  border-radius: 10px;
  margin-top: 15px;
  margin-left: 10px;

}
/* 2021 09 08 */
.position_relative{
  position: absolute;
  top: -2px;
  right: 10px;
  color: #fff;
  text-align: center;
}
.position_relative span{
  opacity: 0.9;
}
.wx{
  background: url(../../assets/images/zhanhui_wx.png) no-repeat !important;
  padding: 20px 0 0 35px !important;
}
.qq{
  background: url(../../assets/images/zhanhui_qq.png) no-repeat !important;
  padding: 20px 0 0 35px !important;
}
.wb{
  background: url(../../assets/images/zhanhui_wb.png) no-repeat !important;
  padding: 20px 0 0 35px !important;
}
</style>