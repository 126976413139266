<template>
    <div class="height">
        <div class="widht1240">
            <div class="btm">
            <span>7*24客服电话：199 1234 8021</span>
            <span>纠错电话：199 1234 8021</span>
            <span>电子邮件：1293851327@qq.com</span>
            </div>
            <div class="btm"><a href="http://beian.miit.gov.cn/" target="_blank">ICP备案号:冀ICP备17015545号-10</a> 网站制度 证照信息公示</div>
            <!-- <div class="justcontent btm"><el-image :src="require('@/assets/images/ba.png')" style="width:18px; height:20px;"></el-image>冀公网安备 13024002000187号</div> -->
            <div class="btm"><a href="https://zzlz.gsxt.gov.cn/businessCheck/verifKey.do?showType=p&serial=911302937554531049-SAIC_SHOW_1000009113029375545310491589943990227&signData=MEUCIQCzkGofkU2D1buoscP5KQEwMY+P6vAYEhXWEq75FaGcmwIgX4gQ2jbo1byAmBPyuXn/8f7pdNeQc4Olf+r6OgDBloM=" target="_blank" >
            <!-- <el-image :src="require('@/assets/images/tb.png')" style="width:27px; height:27px; margin-left:50px;"></el-image> -->
            </a></div>
        </div>
    </div>
</template>
<script>
export default {
    name:"bottominfo",
    data(){
        return{

        }
    }
}
</script>
<style scoped>
.height{
    height: 180px;
    /* padding-top: 45px; */
    background-color: #3A4E73;
}
.height .btm{
    padding: 18px 0;
}
.height span, .height a{
    color: #6c6c6c;
    margin: 0 20px;
    font-size: 14px;
}
.justcontent{
    display: flex;
    align-items: center;
    justify-content: center;
}
.justcontent .el-image{
    margin-right: 10px;
}
.btm{
    color: #7E8BB1;
}
.btm span{
    margin: 0 65px;
    color: #7E8BB1;
}
.btm a{
    margin-right:80px;
    color: #7E8BB1;

}
.margin_botttom29{
    margin-bottom: 29px;
}
</style>